import axios from "axios";
import { useAuth } from "@/components/AuthProvider";
import * as Diff from "diff";
import { Base64 } from "js-base64";
import { useCallback, useRef, useState, useMemo, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import { toast } from "react-hot-toast";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { BiCheckDouble } from "react-icons/bi";
import { BsInfoCircle, BsPlayFill } from "react-icons/bs";
import { FaInfoCircle, FaLock, FaPlay } from "react-icons/fa";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import Modal from "react-modal";
import { FUNCTION_BASE_URL } from "@/utilities/constants";

import Editor, { useMonaco } from "@monaco-editor/react";
import { ArrowPathIcon, PlayIcon } from "@heroicons/react/24/solid";
import { twMerge } from "tailwind-merge";
import Countdown from "react-countdown";
import { addDays, formatDistanceToNowStrict } from "date-fns";
import { useQuestion } from "../TestAttemptController";

// const JUDGE_URL = "http://localhost:4000";
const JUDGE_URL = `${FUNCTION_BASE_URL}/compiler`;

const modes = {
  45: "UNKNOWN",
  46: "shell",
  47: "UNKNOWN",
  1011: "UNKNOWN",
  75: "c",
  1013: "c",
  1001: "c",
  48: "c",
  49: "c",
  50: "c",
  51: "csharp",
  1022: "csharp",
  1021: "csharp",
  1023: "csharp",
  76: "cpp",
  1014: "cpp",
  1002: "cpp",
  52: "cpp",
  53: "cpp",
  54: "cpp",
  1015: "cpp",
  1012: "cpp",
  1003: "c",
  86: "clojure",
  77: "UNKNOWN",
  55: "UNKNOWN",
  56: "UNKNOWN",
  57: "UNKNOWN",
  58: "UNKNOWN",
  44: "plaintext",
  87: "fsharp",
  1024: "fsharp",
  59: "UNKNOWN",
  60: "go",
  88: "UNKNOWN",
  61: "UNKNOWN",
  62: "java",
  1004: "java",
  1005: "java",
  63: "javascript",
  78: "kotlin",
  64: "lua",
  1006: "c",
  1007: "cpp",
  1008: "python",
  1009: "python",
  79: "objective-c",
  65: "UNKNOWN",
  66: "UNKNOWN",
  67: "pascal",
  85: "perl",
  68: "php",
  43: "plaintext",
  69: "UNKNOWN",
  70: "python",
  71: "python",
  1010: "python",
  80: "r",
  72: "ruby",
  73: "rust",
  81: "UNKNOWN",
  82: "sql",
  83: "swift",
  74: "typescript",
  84: "vb",
};

const languages = [
  {
    mode: "java",
  },
  {
    mode: "python",
  },
  {
    mode: "c_cpp",
  },
  {
    mode: "html",
  },
];

const StdInput = ({ languageCode, program, pre_snippet, post_snippet }) => {
  const { token } = useAuth();
  const [compiling, setCompiling] = useState(false);
  const [input, setInput] = useState(null);
  const [output, setOutput] = useState(null);

  const startExecution = useCallback(async () => {
    // let params = {
    //   stdin: input,
    //   source_code: program,
    //   language_id: languageCode,
    // };

    // console.log("Params", params);
    setCompiling(true);

    axios
      .post(
        `${JUDGE_URL}/manualInput`,
        {
          stdin: Base64.encode(input),
          source_code: Base64.encode(program),
          pre_snippet: Base64.encode(pre_snippet || ""),
          post_snippet: Base64.encode(post_snippet || ""),
          language_id: languageCode,
          compiler_type: 1,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token.current}`,
          },
        }
      )
      .then(function (res) {
        setCompiling(false);
        let response = res.data;
        console.log("Response", response);

        if (response.stderr || response.compile_output) {
          setOutput(
            response.compile_output
              ? Base64.decode(response.compile_output)
              : Base64.decode(response.stderr)
          );
        }
        if (response.stdout) {
          setOutput(Base64.decode(response.stdout));
        }
      })
      .catch(function (error) {
        setCompiling(false);
        toast.error(
          "Failed to compile code, please refresh the page and try again"
        );
        console.error(error);
      });
  }, [input, languageCode, program, token]);

  return (
    <div className="flex items-center justify-center gap-3 my-10">
      <div className="flex flex-col items-center justify-center gap-5">
        <button
          type="button"
          disabled={compiling}
          onClick={() => startExecution()}
        >
          {!compiling ? (
            <BsPlayFill className="text-2xl text-white hover:text-yellow-600" />
          ) : (
            <AiOutlineLoading3Quarters className="text-xl font-medium text-white spinner-grow" />
          )}
        </button>
      </div>
      <div className="flex flex-col w-3/12 gap-1">
        <p className="font-medium text-white text-md">Input (editable)</p>
        <textarea
          rows={3}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          className="px-3 py-2 bg-white rounded"
          placeholder="Write your test case..."
        />
      </div>
      <div className="flex flex-col w-8/12 gap-1">
        <p className="font-medium text-white text-md">Output</p>
        <textarea
          disabled
          type="textarea"
          rows={3}
          value={output}
          className="px-3 py-2 text-white bg-black rounded "
        />
      </div>
    </div>
  );
};

const CompilerResults = ({ results }) => {
  return (
    <div>
      <div className="p-5 bg-white rounded-md">
        <Accordion preExpanded={["a"]}>
          {results.map((result, index) => (
            <AccordionItem key={index} uuid={index === 0 ? "a" : null}>
              <AccordionItemHeading
                className={` flex items-center ${
                  result.status.id === 3
                    ? " text-green-600 bg-green-100 hover:bg-green-300"
                    : "text-red-600 bg-red-100 hover:bg-red-300"
                } gap-3 border-2 border-black-111 `}
              >
                <AccordionItemButton className="flex items-center w-full px-3 py-5">
                  <AccordionItemState>
                    {({ expanded }) =>
                      !expanded ? (
                        <FiChevronRight className="text-xl text-black" />
                      ) : (
                        <FiChevronDown className="text-xl text-black" />
                      )
                    }
                  </AccordionItemState>
                  <div className="flex items-center gap-3">
                    <p className="font-medium">{`Test Case ${index + 1}`}</p>
                    {result.status.id === 3 ? (
                      <p className="text-xl font-medium text-green-600">
                        &#10004;
                      </p>
                    ) : (
                      <p className="text-xl font-medium text-red-600">
                        &#10006;
                      </p>
                    )}
                    {result.hidden ? (
                      <p className="font-medium text-yellow-500">(Hidden)</p>
                    ) : null}
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div>
                  {!result.hidden ? (
                    <div className="flex flex-col w-full gap-3 p-5 border border-t-0">
                      <div className="flex items-center gap-2">
                        {result.status.id === 3 ? (
                          <p className="text-xl font-medium text-green-600">
                            &#10004;
                          </p>
                        ) : (
                          <p className="text-xl font-medium text-red-600">
                            &#10006;
                          </p>
                        )}
                        <p
                          className={`${
                            result.status.id === 3
                              ? " text-green-600"
                              : " text-red-600"
                          } text-lg font-medium`}
                        >
                          {result.status.id === 3
                            ? "Your code passed this test case."
                            : "Your code did not pass this test case."}
                        </p>
                      </div>
                      <div className="grid grid-cols-2 gap-5">
                        <div>
                          <p className="font-medium text-black-111 text-md">
                            Input (stdin)
                          </p>
                          <div className="flex w-full h-32 p-3 overflow-auto font-semibold whitespace-pre bg-gray-300">
                            {result.input}
                          </div>
                        </div>
                        <div>
                          <p className="font-medium text-black-111 text-md">
                            Your Output (stdout)
                          </p>
                          <div className="flex w-full h-32 p-3 overflow-auto font-semibold whitespace-pre bg-gray-300">
                            {result.output}
                          </div>
                        </div>
                        <div>
                          <p className="font-medium text-black-111 text-md">
                            Expected Output
                          </p>
                          <div className="flex w-full h-32 p-3 overflow-auto font-semibold whitespace-pre bg-gray-300">
                            {result.expectedOutput}
                          </div>
                        </div>
                        <div>
                          <p className="font-medium text-black-111 text-md">
                            Compiler Message
                          </p>
                          <div
                            className={`p-3 ${
                              result.status.id === 3
                                ? " text-green-600"
                                : " text-red-600"
                            } bg-gray-300 flex w-full font-semibold h-32 whitespace-pre overflow-auto`}
                          >
                            {result.status.description}
                          </div>
                        </div>
                        {result.status.id === 4 ? (
                          <div className="col-span-2">
                            <p className="font-medium text-black-111 text-md">
                              Diff
                            </p>
                            <div
                              className={`p-3 bg-gray-300 w-full font-semibold h-32 whitespace-pre overflow-auto`}
                            >
                              {Diff.diffChars(
                                result.expectedOutput,
                                result.output
                              ).map(({ value, added, removed }) => (
                                <span
                                  className={`${
                                    added
                                      ? "bg-green-600 bg-opacity-25"
                                      : removed
                                      ? "bg-red-600 bg-opacity-25"
                                      : ""
                                  } `}
                                >
                                  {value}
                                </span>
                              ))}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center justify-center border border-t-0 h-60">
                      <div className="flex items-center gap-2">
                        <FaLock className="text-lg text-yellow-500" />
                        <p className="text-lg font-medium text-yellow-500">
                          This is test case is hidden.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

const CodingQuestion = ({
  setFieldValue,
  value,
  test_section_question_id,
  startExecutionofTestCases,
  isCompiling,
  compilerResults,
  apiError,
  setIsCodeChanged,
  loadingMessage,
  is_corrected,
  save_without_compiling,
  disable_autocomplete,
  editorRef,
  save,
  saving,
  disable_copy_paste,
  programming_snippets,
  theme = "vs-dark",
}) => {
  const { isLast, test_section_question } = useQuestion();
  const [showSTDIN, setShowSTDIN] = useState(false);
  const [callback, setCallback] = useState(null);
  const editorRef1 = useRef(null);
  const editorRef2 = useRef(null);
  const editorRef3 = useRef(null);

  const [preSnippet, setPreSnippet] = useState("");
  const [postSnippet, setPostSnippet] = useState("");
  const [preSnippetConfig, setPreSnippetConfig] = useState({});
  const [postSnippetConfig, setPostSnippetConfig] = useState({});

  const getSnippetConfig = useMemo(() => {
    const snippet = programming_snippets.find(
      (snippet) => snippet.language_id === value?.language?.value
    );
    if (snippet) {
      return {
        pre_snippet_config: snippet.pre_snippet_config,
        post_snippet_config: snippet.post_snippet_config,
        pre_snippet: snippet.pre_snippet,
        post_snippet: snippet.post_snippet,
      };
    }
    return {
      pre_snippet_config: {},
      post_snippet_config: {},
      pre_snippet: "",
      post_snippet: "",
    };
  }, [programming_snippets, value?.language?.value]);

  useEffect(() => {
    const {
      pre_snippet,
      post_snippet,
      pre_snippet_config,
      post_snippet_config,
    } = getSnippetConfig;
    setPreSnippet(pre_snippet);
    setPostSnippet(post_snippet);
    setPreSnippetConfig(pre_snippet_config);
    setPostSnippetConfig(post_snippet_config);
  }, [getSnippetConfig]);

  function handleSrcEditorDidMount(editor, editorRef) {
    editorRef.current = editor;
    if (disable_copy_paste) {
      editor.onKeyDown((event) => {
        const { keyCode, ctrlKey, metaKey } = event;
        if (
          (keyCode === 33 || keyCode === 52 || keyCode === 54) &&
          (metaKey || ctrlKey)
        ) {
          toast.error("Copy paste is disabled");
          event.preventDefault();
        }
      });
      editor.onContextMenu(() => {
        toast.error("Context menu is disabled");
      });
      editor.getContainerDomNode().addEventListener(
        "paste",
        (event) => {
          toast.error("Copy paste is disabled");
          event.preventDefault();
          event.stopPropagation();
        },
        true
      );
      editor.getContainerDomNode().addEventListener(
        "copy",
        (event) => {
          toast.error("Copy paste is disabled");
          event.preventDefault();
          event.stopPropagation();
        },
        true
      );
      editor.getContainerDomNode().addEventListener(
        "cut",
        (event) => {
          toast.error("Copy paste is disabled");
          event.preventDefault();
          event.stopPropagation();
        },
        true
      );
    }
  }

  return (
    <>
      {preSnippetConfig?.is_visible && (
        <div className="flex flex-col mb-5">
          <div className="bg-amber-100 font-medium p-2 rounded-t-lg text-sm ">
            Pre Snippet (Not editable)
          </div>
          <div className="h-36">
            <Editor
              theme={theme}
              value={preSnippet}
              onMount={(editor) => handleSrcEditorDidMount(editor, editorRef1)}
              defaultLanguage={modes[value.language?.value] ?? "python"}
              options={{
                readOnly: !preSnippetConfig.is_editable,
                minimap: { enabled: false },
                lineNumbers: "off",
                folding: false,
                maxLines: 5,
                minLines: 5,
              }}
            />
          </div>
        </div>
      )}
      <div className="h-96">
        <div className="bg-gray-E5 font-medium p-2 rounded-t-lg text-sm w-1/6 flex justify-between items-center">
          <span>Main Code</span>
        </div>
        <Editor
          theme={theme}
          defaultValue=""
          defaultLanguage={modes[value.language?.value] ?? "python"}
          onMount={(editor) => handleSrcEditorDidMount(editor, editorRef2)}
          onChange={(e) => {
            setIsCodeChanged(true);
            console.log("value", value.language);
            setFieldValue("answer", {
              code: e,
              language: value.language,
            });
          }}
          value={value?.code ?? ""}
          options={{
            automaticLayout: true,
            scrollBeyondLastLine: true,
            readOnly: is_corrected ? true : false,
            minimap: {
              enabled: true,
            },
            dropIntoEditor: {
              enabled: !disable_copy_paste,
            },
            contextmenu: !disable_copy_paste,
          }}
          language={modes[value.language?.value] ?? "python"}
        />
      </div>

      {postSnippetConfig?.is_visible && (
        <div className="flex flex-col mt-14 mb-5">
          <div className="bg-amber-100 font-medium p-2 rounded-t-lg text-sm ">
            Post Snippet (Not editable)
          </div>
          <div className="h-36">
            <Editor
              theme={theme}
              value={postSnippet}
              onMount={(editor) => handleSrcEditorDidMount(editor, editorRef3)}
              defaultLanguage={modes[value.language?.value] ?? "python"}
              options={{
                readOnly: !postSnippetConfig.is_editable,
                minimap: { enabled: false },
                lineNumbers: "off",
                folding: false,
                maxLines: 5,
                minLines: 5,
              }}
            />
          </div>
        </div>
      )}

      <Modal
        isOpen={callback !== null}
        onRequestClose={() => {
          setCallback(null);
        }}
        htmlOpenClassName="overflow-hidden"
        bodyOpenClassName="overflow-hidden"
        className="inset-x-auto inset-y-auto w-64 px-5 bg-white rounded-md sm:w-80 focus:outline-none"
        overlayClassName="transition-all ease-in-out duration-300 flex justify-center items-center bg-opacity-75 bg-black inset-0 fixed p-8 z-50"
      >
        <header className="flex justify-end p-5">
          <button
            onClick={() => {
              setCallback(null);
            }}
          >
            <MdClose className="w-6 h-6" />
          </button>
        </header>
        <div className="flex justify-center px-5 mb-5">
          <BsInfoCircle className="w-16 h-16 text-yellow-600" />
        </div>
        <div className="flex flex-col gap-2 px-5 text-center">
          Once you finalize and submit the code you won't be able to make any
          changes. Are you sure you want to continue?
        </div>
        <div className="flex justify-center gap-5 my-5">
          <button
            onClick={() => {
              setCallback(null);
            }}
            className="px-5 py-2 border border-gray-500 rounded-md"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              if (callback) callback();
            }}
            className="px-5 py-2 bg-green-600 rounded-md"
          >
            <div className="flex items-center gap-1 text-white">
              <BiCheckDouble />
              <p>Yes, submit</p>
            </div>
          </button>
        </div>
      </Modal>
      {!save_without_compiling && (
        <div className={`flex justify-end p-5 gap-5 bg-gray-700`}>
          {is_corrected ? (
            <div className="text-white">
              You have already submitted the code
            </div>
          ) : (
            <div className={`flex justify-end p-5 gap-5 bg-gray-700`}>
              <div>
                <button
                  onClick={
                    () =>
                      startExecutionofTestCases(
                        value.language.value,
                        value.code,
                        preSnippet,
                        postSnippet,
                        test_section_question_id,
                        value.language.label,
                        value.language.mode,
                        "SUBMIT"
                      )
                    // setCallback(() => () => {
                    //   setCallback(null);
                    // })
                  }
                  type="button"
                  className="px-3 py-2 bg-green-600 rounded"
                >
                  {!isCompiling ? (
                    <div className="flex items-center gap-1 text-white">
                      <PlayIcon className="w-4 h-4" />
                      <p>Run Code</p>
                    </div>
                  ) : (
                    <div className="flex items-center gap-1">
                      <AiOutlineLoading3Quarters className="text-xl font-medium text-blue-600 spinner-grow" />
                      <p>Please wait...</p>
                    </div>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      {!save_without_compiling && (
        <div className="p-5 bg-gray-500">
          <div>
            <div className="flex items-center gap-2 mx-5 my-2">
              <input
                type="checkbox"
                checked={showSTDIN}
                onChange={() => setShowSTDIN(!showSTDIN)}
              />
              <label className="text-white">Run custom test cases</label>
            </div>

            {showSTDIN ? (
              <StdInput
                program={value.code}
                languageCode={value.language.value}
                pre_snippet={preSnippet}
                post_snippet={postSnippet}
              />
            ) : null}
          </div>

          {isCompiling ? (
            <Modal
              isOpen={isCompiling}
              htmlOpenClassName="overflow-hidden"
              bodyOpenClassName="overflow-hidden"
              closeTimeoutMS={300}
              className="inset-x-auto inset-y-auto flex items-center justify-center w-1/3 h-24 max-h-full p-8 overflow-y-auto bg-white rounded-md focus:outline-none"
              overlayClassName="transition-all ease-in-out duration-300 flex justify-center items-center bg-opacity-50 bg-black inset-0 fixed p-8 z-50"
            >
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center gap-2">
                  <AiOutlineLoading3Quarters className="text-xl font-medium text-blue-600 spinner-grow" />
                  <p className="text-xl font-medium">{loadingMessage}</p>
                </div>
              </div>
            </Modal>
          ) : null}
          <div id="results" className="mx-5 my-30">
            {compilerResults && !apiError ? (
              <CompilerResults results={compilerResults} />
            ) : apiError ? (
              <div className="flex justify-center m-5 text-xl text-white">
                <p>Error fetching data</p>
              </div>
            ) : null}
          </div>
        </div>
      )}
      <div className="flex flex-col items-end justify-end w-full px-5 mt-5">
        <button
          onClick={() => save(true, true)}
          type="button"
          className={twMerge(
            `px-5 py-2 text-sm flex items-center gap-2 text-white bg-blue-800 rounded-md lg:relative lg:w-auto`,
            saving ? "bg-gray-900 cursor-not-allowed bg-opacity-75" : ""
          )}
          disabled={saving}
        >
          {saving ? <ArrowPathIcon className="w-5 h-5 animate-spin" /> : null}
          {saving ? "Saving..." : isLast ? "Save" : "Save & Next"}
        </button>
        <Countdown
          autoStart={true}
          renderer={() => {
            return (
              <span className="text-xs">
                {`Updated ${
                  test_section_question?.test_question_submissions?.[0]
                    ? formatDistanceToNowStrict(
                        new Date(
                          test_section_question?.test_question_submissions?.[0].updated_at
                        ),
                        { addSuffix: true }
                      )
                    : "never"
                }`}
              </span>
            );
          }}
          date={addDays(new Date(), 1)}
          intervalDelay={5000}
        />
      </div>
    </>
  );
};

export default CodingQuestion;
